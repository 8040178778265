@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

@import "abstracts/variables-bootstrap";
@import "bootstrap";
@import "abstracts/mixins";
@import "abstracts/variables";
@import "partials/fonts";

/** LAYOUT **/
@import "layout/header";
@import "layout/footer";
@import "layout/mainmenu";


/** PAGES **/
@import "pages/cart";
@import "pages/category";
@import "pages/home";
@import "pages/order";
@import "pages/product";

@import "partials/commons";
@import "partials/lazysizes";
/** COMPONENT **/
@import "components/alert";
@import "components/block-social";
@import "components/cart";
@import "components/categories";
@import "components/customer";
@import "components/footer";
@import "components/imageslider";
@import "components/offcanvas";
@import "components/products";
@import "components/searchbar";
@import "components/facet";
@import "components/search-widget";
@import "components/slick";
@import "components/slick-theme";
@import "components/productcomments";
@import "components/utilities";
@import "partials/bs_alpha";



.products.slick-slider{
  display: flex !important;
}


body
{
  background-color: white;
  letter-spacing: 2px;
  font-family: 'Quicksand', sans-serif !important;
}


.pdwcookies_message p
{
text-align: center;
}


body  ,
body p ,
body h1 , body h2, body h3,
body ul li

{
  font-family: 'Quicksand', sans-serif !important;
}




.footer__title {
  color: #212529;
  font-weight: 300;
  margin-bottom: 1rem;
  font-size: 1.3rem;
}



.page-header {

  background-color: transparent;
  border-bottom: none;
}


.card-block
{
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid rgba(0,0,0,.125);
  margin-bottom: 40px;


}




//Counters
.nested-counters{
  ol, ul {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;

    > li {
      display: table;
      counter-increment: item;
      margin-bottom: 1rem;

      &:before {
        content: counters(item, ".") ". ";
        display: table-cell;
        padding-right: 0.6rem;
      }

      > ol > li {
        margin: 0;
        margin-bottom: 0.5rem;

        &:before {
          content: counters(item, ".") " ";
        }
      }
    }
  }
  ol ul {
    > li {
      margin-bottom: 0.5rem;

      &:before {
        font-family: "Material Icons";
        content: "\e5cc";
        vertical-align: top;
        padding-top: 1px;

      }

      > ul > li {
        margin: 0;

        &:before {
          font-family: "Material Icons";
          content: "\e5cc";
          vertical-align: top;
          padding-top: 1px;
        }
      }
    }
  }
}

.product-description-short,
.product-description,
.page-content--cms
{
  ul{
    list-style-type: disc;
    padding-left: 15px;
  }
}

.contact-form form {
  width: auto;
  max-width: inherit;
}


/* fix popup */


.modal .slick-slide{
  text-align: center;
}


.btn-primary {
  background-color: #f0cd5b;
  border-color: #f0cd5b;
  color: #ffffff;
}


.product-flag {

  background: #F0CD5B;

}

.btn-primary:hover {
  background-color: #F0CD5B;
  border-color: #F0CD5B;
}

.nav-tabs .nav-link.active {
  color: #f0cd5b;
  border-color: transparent transparent #f0cd5b;
}


.breadcrumb {
  background-color: transparent;
  border-bottom: solid 1px #e7e7e7;
}

a {
  color: #ec691c;
}



#contact-link a
{
  color: #343a40;
}


.products-section-title {
  margin-bottom: 2rem;
}

.product-flag {

  box-shadow : none;
}

.product-title a {
  font-size: 15px;
}


.header__right span
{
  text-transform : uppercase;
}

.products-section-title, h1 {
  font-size: 1.7rem;
  font-weight: 500;
}


.slider .vc_col-sm-4 ,
.slider .vc_col-sm-12
{
  @include media-breakpoint-up(sm) {
    padding-left: 0 !important;
  }
}







.menu__item-link--top {

 font-weight: 400;
  font-size: 19px;

  @include media-breakpoint-up(md) {
    text-align: center;

  }
}

body  .wishlist-modal .modal-cancel {
  background: #232323;

}

body .blockreassurance_product .item-product {
  margin-right: 15px !important;
}

.blockreassurance_product .block-title
{
font-weight: 600;
}



.l-header {
  box-shadow: none;
}



.head-title  {
  padding: 15px;
  font-weight: 600;
  background-color: rgba(0, 0, 0, 0.15);
  justify-content: center;
  transition: all 200ms;
  -webkit-transition: all 200ms;
  @include media-breakpoint-up(lg) {
    margin-left: 50px;
    margin-right: 50px;
  }
}


.form-control {

  box-shadow: none;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid #ced4da;
}


.title_block {
  font-size: 26px;
  letter-spacing: 2px;
  text-align: center;
  /*
   border-bottom: 1px solid #e5e5e5;
     padding-bottom: 15px;
     */
  text-transform: uppercase;

  margin-bottom: 40px;
  font-weight: 300!important;
}

.slider-hp .head-title .wpb_wrapper
{
  padding: 15px;
  font-weight: 600;
  background-color: rgba(0, 0, 0, 0.25);
  justify-content: center;
  transition: all 200ms;
  -webkit-transition: all 200ms;
  max-width: 50%;
}

.box-services .head-title:hover
{
  background-color: rgba(240,205,91,.65);
}

b,
strong
{
  font-weight: 600;
}



.box-services
{
  position: relative;
}


.btn-primary:disabled
{
  color: #ffffff;
}



._desktop_category_header h1
{

  font-weight: 300 !important;

}

.head-title h2
{
  text-transform: uppercase;
  color: white;
  letter-spacing: 2px;
  font-size: 21px;
  text-align: center;
  font-weight: 300 !important;

}

.head-title  h2 a:hover ,
.head-title  h2 a
{
  color: white;
}




.page-product #main
{
  padding-top: 30px;
}




.modal-content .btn-zoom.product-layer-zoom
{
  display: none;
}



#payment-option-2-container img
{
  max-width: 30%;
}





